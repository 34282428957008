<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">General</span>
      </template>

      <general
        v-if="options.general"
        :general-data="options.general"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Change Password</span>
      </template>

      <change-password />
    </b-tab>
    <!--/ change password tab -->

    <!-- notification -->
    <b-tab disabled>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="BellIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Notifications</span>
      </template>

      <notification-settings
        v-if="options.notification"
        :notification-data="options.notification"
      />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import api from '@/libs/api'
import General from './General.vue'
import ChangePassword from './ChangePassword.vue'
import NotificationSettings from './NotificationSettings.vue'

export default {
  components: {
    BTabs,
    BTab,
    General,
    ChangePassword,
    NotificationSettings,
  },
  data() {
    return {
      options: {
        general: {
          avatar: '',
          firstName: '',
          lastName: '',
          email: '',
          company: '',
        },
        info: {
          bio: '',
          dob: null,
          country: 'USA',
          website: '',
          phone: 6562542568,
        },
        social: {
          socialLinks: {
            twitter: 'https://www.twitter.com',
            facebook: '',
            google: '',
            linkedIn: '',
            instagram: '',
            quora: '',
          },
          connections: {
            twitter: {
              profileImg: '/img/11-small.4ade0856.png',
              id: 'johndoe',
            },
            google: {
              profileImg: '/img/3-small.33d29f56.png',
              id: 'luraweber',
            },
            facebook: {},
            github: {},
          },
        },
        notification: {
          commentOnArticle: true,
          AnswerOnForm: true,
          followMe: false,
          newAnnouncements: true,
          productUpdates: true,
          blogDigest: false,
        },
      },
    }
  },
  mounted() {
    this.getAccountDetails()
  },
  methods: {
    getAccountDetails() {
      api.getData('account_management/user_account/', true).then(response => {
        if (response.status === 200) {
          this.options.general.firstName = response.data.primary_traveller.first_name
          this.options.general.lastName = response.data.primary_traveller.last_name
          this.options.general.email = response.data.email
          this.options.general.avatar = response.data.primary_traveller.image
        }
      })
    },
  },
}
</script>
